import * as React from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";

const navigation = [
  // { name: "About", href: "/about" },
  // { name: "Services", href: "/services" },
  // { name: "Process", href: "/process" },
  // { name: "Blog", href: "/blog" },
];

const Header = () => {
  return (
    <Popover>
      <div className="max-w-7xl mx-auto px-4 py-6 md:py-16">
        <nav
          className="relative flex items-center justify-between max-h-20 sm:h-14 md:justify-center overflow-y-hidden"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">Hyperfluid Solutions Logo</span>
                <StaticImage
                  src="../images/icon.png"
                  alt="hyperfluid solutions icon"
                  height={50}
                />
              </a>
              <a href="/" className="md:hidden xl:block">
                <span
                  style={{ lineHeight: 70 }}
                  className="font-logo text-2xl pl-4"
                >
                  Hyperfluid <span className="text-blue-900">Solutions</span>
                </span>
              </a>
              <a href="/" className="hidden md:block xl:hidden">
                <span
                  style={{ lineHeight: 70 }}
                  className="font-logo text-2xl pl-4"
                >
                  Hyperfluid
                </span>
              </a>
              <div className="flex items-center md:hidden">
                <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-700">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:space-x-10">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="font-medium text-gray-500 hover:text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <a
              className="hidden font-medium text-gray-500 hover:text-gray-900 mr-4 lg:flex animate-underline"
              href="tel:+14126261556"
            >
              +1 (412) 626-1556
            </a>
            <span className="inline-flex rounded-md shadow">
              <a
                href="/contact"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-purple-800 bg-white hover:bg-gray-50"
              >
                Contact us
              </a>
            </span>
          </div>
        </nav>
      </div>

      <Transition
        as={React.Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <StaticImage
                  src="../images/icon.png"
                  alt="hyperfluid solutions icon"
                  width={50}
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-700">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <a
              className="block w-full px-5 py-3 text-center font-medium text-purple-800 hover:bg-gray-100 animate-underline"
              href="tel:+14126261556"
            >
              +1 (412) 626-1556
            </a>
            <a
              href="/contact"
              className="block w-full px-5 py-3 text-center font-medium text-purple-800 bg-gray-50 hover:bg-gray-100"
            >
              Contact us
            </a>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
